import './index.css';
import '../style.css';
import { useState } from 'react';
import mailSend from '../emailkey';
import InputMask from "react-input-mask";

const Order = ({setRegistration}) => {
  const [zip, setZip] = useState('');
  const [tel, setTel] = useState('');
  const [name, setName] = useState('');
  const changeDate = undefined;
  const [colorMail, setColorMail] = useState(true);
  const [colorTel, setColorTel] = useState(true)
  const [colorName, setColorName] = useState(true)
  const [error, setError] = useState(false)

  const handlerClick = () => {
    setError(false)
    if (( tel.indexOf('_') !== -1) | ( tel.length === 0 ) ) {
      setColorTel(false);
    } else {
      setColorTel(true);
    }

    if ( ( zip.indexOf('_') !== -1 ) | ( zip.length === 0 ) ) {
      setColorMail(false);
    } else {
      setColorMail(true);
    }

    if ( name.length === 0 ) {
      setColorName(false)
    } else {
      setColorName(true);
    }

    if (!(zip.indexOf('_') !== -1) && !(tel.indexOf('_') !== -1) && ((name.length && zip.length && tel.length) !== 0)) {
      mailSend(zip, tel, name, changeDate)
      .then(response => {
        if (response.message === "Ops, this form is broken, just call us on the mainline (408) 484-4353") {
          setError(true)
          return
        } else {
          setZip('');
          setTel('');
          setName('');

          const mainPage = document.getElementById("main-page-all");
          mainPage.classList.remove("show");
          mainPage.classList.add("hidden");

          const requestPage = document.getElementById("request-page");
          requestPage.classList.remove("hidden");
          requestPage.classList.add("show");
        }
      })
      .catch(error => {
        setError(true)
      })
    }
  };

  return (
    <div className="order">
      <div className="order-title title t42 white">Leave your application right now</div>
      {error && <div className="order-text text t16-140 white">
        Ops, this form is broken, <br className='br-phone' /> just call us on the mainline <br className='br-phone' /> (408) 484-4353
      </div>}
      {!error && colorMail && colorTel && colorName && <div className="order-text text t16-140 white">
        Complete the form, and we'll call you back soon
      </div>}
      {!error && !(colorMail && colorTel && colorName) && <div className="order-text text t16-140 white">
        One or more fields have an error. Please check and try again.
      </div>}
      <div className="order-inputs">
        <input 
          className={ colorName ?  "order-input-name" : "order-input-name red border-red"}
          placeholder="Name"
          type="text"
          value={name}
          onChange={event => setName(event.target.value)}
        ></input>
          <InputMask mask="(999) 999-9999" value={tel} onChange={e => setTel(e.target.value)}>
            {(inputProps) => (
              <input
                {...inputProps}
                type="tel"
                className={ colorTel ?  "order-input-email" : "order-input-email red border-red"}
                disableUnderline
                placeholder="Phone number"
              />
            )}
          </InputMask>
          <InputMask mask="99999" value={zip} onChange={e => setZip(e.target.value)}>
            {(inputProps) => (
              <input
                {...inputProps}
                type="tel"
                className={ colorMail ? "order-input-email" : "order-input-email red border-red"}
                disableUnderline
                placeholder="Zip"
              />
            )}
          </InputMask>
        <button
          className="order-button text t14-17 white"
          onClick={handlerClick} 
        >Request service</button>
      </div>
    </div>
  )
}

export default Order;
