const mailSend = async (zip, tel, name, date, message = null) => {
    if (!date) {
        date = new Date()
    }

    const formData = new FormData()

    const templateParams = {
        name: name,
        phone: tel,
        zip: zip,
        date: date,
    };

    formData.append('your-name', templateParams.name);
    formData.append('tel-672', templateParams.phone);
    formData.append('text-429', templateParams.zip);

    if (message) {
        formData.append('message', message);
    }

    const response = await fetch('https://master-appliance.com/wp-json/contact-form-7/v1/contact-forms/2437/feedback', {
        method: 'POST',
        body: formData
    });
    const data = await response.json();

    return data;
}

export default mailSend
