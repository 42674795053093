import React from 'react';
import ReactDOM from 'react-dom';
import Calendar from './calendar';
import Order from './Order';
import MainMenu from './MainMenu';

ReactDOM.render(
  <React.StrictMode>
    <Calendar />
  </React.StrictMode>,
  document.getElementById('calendar')
);

ReactDOM.render(
  <React.StrictMode>
    <Calendar />
  </React.StrictMode>,
  document.getElementById('calendar2')
);

ReactDOM.render(
  <React.StrictMode>
    <Order />
  </React.StrictMode>,
  document.getElementById('order1')
);

ReactDOM.render(
  <React.StrictMode>
    <Order />
  </React.StrictMode>,
  document.getElementById('order2')
);

ReactDOM.render(
  <React.StrictMode>
    <MainMenu />
  </React.StrictMode>,
  document.getElementById('mainMenu')
);

ReactDOM.render(
  <React.StrictMode>
    <MainMenu />
  </React.StrictMode>,
  document.getElementById('mainMenu1')
);

ReactDOM.render(
  <React.StrictMode>
    <MainMenu />
  </React.StrictMode>,
  document.getElementById('mainMenu2')
);